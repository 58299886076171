import React, { useState, useEffect } from "react";
import _ from "lodash";
import { TransitionGroup } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment"
import "./dnd.css"

import { db } from "../../services/firebase";

function DND() {
  const [DNDTitle, setDNDTitle] = useState("");
  const [DNDPerson, setDNDPerson] = useState("");
  const [DNDSession, setDNDSession] = useState("");
  const [DNDFrom, setDNDFrom] = useState("");

  const [DNDs, setDNDs] = useState("");
  const [DNDInfo, setDNDInfo] = useState("");

  const rootRef = "DND/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setDNDs(sampleArray.slice(0, -1));
        setDNDInfo(snapshot.val().info)
      }
    });
  }, [rootRef]);

  const resetState = () => {
    setDNDFrom("");
    setDNDTitle("");
    setDNDPerson("");
  };

  // TODO: Lag mulighet for hemmelighe notater fra DM
  // TODO: Lage private notater på hver spiller 

  const endSession = () => {
    // A post entry.
    let postData = {
      title: "--- Session " + DNDSession + " avsluttet ---",
      person: null,
      session: "Session " + DNDSession ? DNDSession : null,
      from: DNDFrom ? DNDFrom : "DM",
      date: Date(),
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("DND").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    upSessionNumber()
    return db.ref().update(updates);


  }

  const upSessionNumber = () => {
    // A post entry.
    let postData = {
      session: DNDSession,
      story: DNDInfo.story,
    };


    let updates = {};
    updates[rootRef + "info"] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  }

  const writeDND = () => {
    // A post entry.
    let postData = {
      title: DNDTitle,
      person: DNDPerson,
      session: DNDSession ? DNDSession : null,
      from: DNDFrom ? DNDFrom : "DM",
      date: Date(),
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("DND").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  };

  return (
    <div className='container mainContent dnd'>
      <div className='row'>
        <div className='col-6'>
          <h1>DND</h1>
          <TransitionGroup
            transitionName='example'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {DNDs ? (
              <div className='row'>
                <h4>Storyline - {DNDInfo.story}</h4>
                <h5>Forrige session var session nummer {DNDInfo.session}</h5>
                <p className="text-muted">Dette er DM notes, ikke nødvendigvis mye hemmelig men her står det ting jeg jobber for hver session</p>
                {DNDs.toReversed().map((DND, i) => (


                  <div key={i} className='dnd-entry col-12'>
                    <h2>{DND.title}</h2>
                    {DND.person ? <h5 classname="text-muted">PC - {DND.person}</h5> : null}

                    <h5 className='text-muted'>{moment(DND.date).format('MM/D/YY h:mm:ss')} - {DND.session ? "Session " + DND.session : null}</h5>
                  </div>



                ))}
              </div>
            ) : (
              <div>Ingenting å vise her</div>
            )}
          </TransitionGroup>
        </div>
        <div className='col-6'>
          <input
            className='form-control'
            type='text'
            placeholder='Session'
            required
            value={DNDSession}
            onChange={(e) => setDNDSession(e.target.value)}
          />
          <input
            className='form-control'
            type='text'
            placeholder='Event'
            required
            value={DNDTitle}
            onChange={(e) => setDNDTitle(e.target.value)}
          />
          <input
            className='form-control text-muted'
            type='text'
            placeholder='Affected characters if any'
            value={DNDPerson}
            onChange={(e) => setDNDPerson(e.target.value)}
          />

          <button
            onClick={writeDND}
            className='btn btn-primary'
            type='button'
            disabled={!DNDTitle}
          >
            Loggfør
          </button>
          <button
            onClick={endSession}
            className='btn btn-danger'
            type='button'
            disabled={!DNDSession}
          >
            End session
          </button>

          <div className="row">
            <h2>Copyable logg (Nyeste nederst)</h2>
            {DNDs ? (
              <div>
                {DNDs.map((DND, i) => (
                  <div key={i} className='col-12'>
                    <p>{moment(DND.date).format('MM/D/YY h:mm:ss') + " (Session " + DND.session + ") - " + DND.title + " - Affected PC:"}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Ingenting å vise her</div>
            )}



          </div>
        </div>
      </div>
    </div>
  );
}

export default DND;
