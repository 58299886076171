import React, { useState, useEffect } from "react";
import _ from "lodash";
import { TransitionGroup } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";

import { db } from "../services/firebase";

function Suggestions() {
  const [suggestionTitle, setSuggestionTitle] = useState("");
  const [suggestionFrom, setSuggestionFrom] = useState("");
  const [suggestionDesc, setSuggestionDesc] = useState("");

  const [suggestions, setSuggestions] = useState("");

  const rootRef = "suggestions/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setSuggestions(sampleArray);
      }
    });
  }, [rootRef]);

  const resetState = () => {
    setSuggestionDesc("");
    setSuggestionFrom("");
    setSuggestionTitle("");
  };

  const writeSuggestion = () => {
    // A post entry.
    let postData = {
      title: suggestionTitle,
      desc: suggestionDesc,
      from: suggestionFrom ? suggestionFrom : "System",
      status: 1,
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("suggestions").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  };
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h3>Roadmap</h3>
          <p>(for det meste så jeg husker selv hva faen jeg skal gjøre)</p>
          <TransitionGroup
            transitionName='example'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {suggestions ? (
              <div className='row'>
                {suggestions.map((suggestion, i) => (
                  <div key={i} className='entry col-6'>
                    <h3 className='title'>{suggestion.title}</h3>
                    <div className='desc'>{suggestion.desc}</div>
                    <small>{suggestion.from}</small>
                    {suggestion.status === 1 ? (
                      <span className='badge bg-secondary'>Ny</span>
                    ) : null}
                    {suggestion.status === 2 ? (
                      <div className='badge bg-primary'>Startet</div>
                    ) : null}
                    {suggestion.status === 3 ? (
                      <div className='badge bg-success'>Ferdig</div>
                    ) : null}
                    {suggestion.status === 4 ? (
                      <div className='badge bg-warning'>På vent</div>
                    ) : null}
                    {suggestion.status === 5 ? (
                      <div className='badge bg-danger'>Ikke mulig</div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <div>Ingenting å vise her</div>
            )}
          </TransitionGroup>
        </div>
      </div>
      <br></br>
      {/* <div className='row'>
        <div className='col-12'>
          <input
            className='form-control'
            type='text'
            placeholder='Tittel'
            value={suggestionTitle}
            onChange={(e) => setSuggestionTitle(e.target.value)}
          />

          <input
            className='form-control'
            type='text'
            placeholder='Beskrivelse'
            value={suggestionDesc}
            onChange={(e) => setSuggestionDesc(e.target.value)}
          />

          <input
            className='form-control'
            type='text'
            placeholder='Fra (valgfritt)'
            value={suggestionFrom}
            onChange={(e) => setSuggestionFrom(e.target.value)}
          />

          <button
            onClick={writeSuggestion}
            className='btn btn-primary'
            type='button'
          >
            Send inn forslag
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default Suggestions;
