import React, { useState, useEffect } from "react";
import { db } from "../services/firebase";

//Bosse Wibergh

import Altibox from "../img/logos/altibox.png";
import Danske_Bank_logo from "../img/logos/danskebank.png";
import Duett from "../img/logos/duett.png";
import google from "../img/logos/google.png";
import icloud from "../img/logos/icloud.png";
import lastpass from "../img/logos/lastpass.png";
import messenger from "../img/logos/messenger.webp";
import santander from "../img/logos/santander.png";
import viafree from "../img/logos/viafree.webp";
import viaplay from "../img/logos/viaplay.png";
import dplay from "../img/logos/dplay.png";
import facebook from "../img/logos/facebook.webp";
import norwegian from "../img/logos/norwegian.png";
import sor from "../img/logos/sor.png";
import svt from "../img/logos/svt.webp";
import spotify from "../img/logos/spotify.png";
import handelsbanken from "../img/logos/handelsbanken.png";
import gulesider from "../img/logos/gulesider.svg";
import sverigeradio from "../img/logos/sverigeradio.webp";
import svtplay from "../img/logos/svtplay.png";
import tfbank from "../img/logos/tfbank.webp";
import dnb from "../img/logos/dnb.png";


import "../assets/css/bw.css";
// import "bootstrap/dist/css/bootstrap.min.css";

function BW() {
  const rootRef = "BW/requests";
  const [pageTitle, setPageTitle] = useState("");

  // useEffect(() => {
  //   db.ref(rootRef).on("value", (snapshot) => {
  //     if (snapshot.val()) {

  //     }
  //   });
  // }, [rootRef]);


  const addSuggestion = () => {
    // A post entry.
    let postData = {
      title: pageTitle,
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("DND").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  };

  const resetState = () => {
    setPageTitle("");
  };




	return (
    <div className='bw-background'>

      <div className='bw-container'>
        <h5 className='seperator'>Jeg er glad i deg pappa ❤️</h5>
        <br></br>
        <h2 className='seperator'>Verktøy</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.google.com'>
            <img
              alt='img'
              className='img'
              src={Altibox}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.drive.google.com'>
            <img
              alt='img'
              className='img-small'
              src={google}
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://www.icloud.com'>
            <img
              alt='img'
              className='img-small'
              src={icloud}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.lastpass.com'>
            <img
              alt='img'
              src={lastpass}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://rf.duett.no/Duett/Core/FrontEnd/WebApplications/Dashboard/'>
            <img
              alt='img'
              src={Duett}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://danskebank.no/privat/faa-hjelp?n-login=pbnettbank#'>
            <img
              alt='img'
              src={Danske_Bank_logo}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://gulesider.no'>
            <img
              alt='img'
              src={gulesider}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.altibox.no/minesidero'>
            <img
              alt='img'
              src={Altibox}
            />
          </a>
        </div>

        <h2 className='seperator'>Sosialt</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.facebook.com'>
            <img
              alt='img'
              className='img-small'
              src={facebook}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.messenger.com'>
            <img
              alt='img'
              className='img-small'
              src={messenger}
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://play.spotify.com/'>
            <img
              alt='img'
              className='img-small'
              src={spotify}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.sverigesradio.se'>
            <img
              alt='img'
              src={sverigeradio}
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://www.svt.se/'>
            <img
              alt='img'
              src={svt}
            />
          </a>
        </div>

        <h2 className='seperator'>TV / Streaming</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.svtplay.se/'>
            <img
              alt='img'
              className='img-small'
              src={svtplay}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.viafree.se/'>
            <img
              alt='img'
              src={viafree}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.dplay.se/'>
            <img
              alt='img'
              src={dplay}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.viaplay.se/'>
            <img
              alt='img'
              src={viaplay}
            />
          </a>
        </div>

        <h2 className='seperator'>Bank</h2>
        <div className='box white-bg shadow-generic'>
          <a href='https://nettbank.sor.no/'>
            <img
              alt='img'
              src={sor}
            />
          </a>
        </div>


        <div className='box white-bg shadow-generic'>
          <a href='https://www.dnb.no/'>
            <img
              alt='img'
              src={dnb}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://tfbank.no/minside'>
            <img
              alt='img'
              src={tfbank}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://minnettbank.santanderconsumer.no/'>
            <img
              alt='img'
              src={santander}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://identity.banknorwegian.no/MyPage/Login?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmypageno%26redirect_uri%3Dhttps%253A%252F%252Fwww.banknorwegian.no%252Fsignin-oidc-no%26response_type%3Dcode%2520id_token%26scope%3Dopenid%2520profile%2520offline_access%2520bn_token%26state%3DOpenIdConnect.AuthenticationProperties%253D2K9Tlh-49Pqo8AYuawx9JlKg0jmSmz7NG2c3m8Xk75xkCQot7xmvTxuGQhdpGxsvLpSEiSxDD2xDPAmi-ICMfAL9D49-qEPMIHai_KjLhtnDzRt35btqPGGyvbAvHBQd_7b6_5YCgC3VbZzBm1cy5ItlnoTqCn5D0LXTq9-yGlOzRYyMMuocZ0HKye0QRMiOfQ6Vew%26response_mode%3Dform_post%26nonce%3D637439792853831803.MzRlZmQwZTAtZWYwYy00MGIzLWE2OTAtMGE0YjQ2Mjk5ZGNkNjRjOGNhOTktYjU5Yy00NzE1LTg0MDctYTE3YWNiZGMxNjIw%26acr_values%3Didp%253Abn%26dm%3D0%26sid%3De790d35f-86d0-43dc-9934-7f53196cc4d7%26region%3Dnb-NO'>
            <img
              alt='img'
              src={norwegian}
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://secure.handelsbanken.se/logon/se/priv/sv/'>
            <img
              alt='img'
              src={handelsbanken}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BW;
