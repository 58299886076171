import React, { useState, useEffect } from "react";
import _ from "lodash";
import { TransitionGroup } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment"
import "./dnd-items.css"

import { db } from "../../services/firebase";

function DndItems() {
  const [DNDTitle, setDNDTitle] = useState("");
  const [DNDPerson, setDNDPerson] = useState("");
  const [DNDSession, setDNDSession] = useState("");
  const [DNDFrom, setDNDFrom] = useState("");

  const [Items, setItems] = useState("");
  const [DNDInfo, setDNDInfo] = useState("");

  const rootRef = "DND_Items/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        Items(sampleArray.slice(0, -1));
      }
    });
  }, [rootRef]);

  const resetState = () => {
    setDNDFrom("");
    setDNDTitle("");
    setDNDPerson("");
  };

  // TODO: Lag mulighet for hemmelighe notater fra DM
  // TODO: Lage private notater på hver spiller 


  const addItem = () => {
    // A post entry.
    let postData = {
      title: DNDTitle,
      person: DNDPerson,
      session: DNDSession ? DNDSession : null,
      from: DNDFrom ? DNDFrom : "DM",
      date: Date(),
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("DND").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  };

  return (
    <div className='container mainContent dnd'>
      <div className='row'>
        <div className='col-12'>
          <h1>DND items</h1>
          <TransitionGroup
            transitionName='example'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {Items ? (
              <div className='row'>
                <h4>Storyline - {DNDInfo.story}</h4>
                <h5>Forrige session var session nummer {DNDInfo.session}</h5>
                <p className="text-muted">Dette er DM notes, ikke nødvendigvis mye hemmelig men her står det ting jeg jobber for hver session</p>
                {Items.toReversed().map((item, i) => (
                  <div key={i} className='dnd-entry col-12'>
                    <h2>{item.title}</h2>
                    {item.person ? <h5 classname="text-muted">PC - {item.person}</h5> : null}

                    <h5 className='text-muted'>{moment(item.date).format('MM/D/YY h:mm:ss')} - {item.session ? "Session " + item.session : null}</h5>
                  </div>

                ))}
              </div>
            ) : (
              <div>Ingenting å vise her</div>
            )}
          </TransitionGroup>
        </div>
        <div className='col-12'>
          <h2>Add item</h2>
          <input
            className='form-control'
            type='text'
            placeholder='Session'
            required
            value={DNDSession}
            onChange={(e) => setDNDSession(e.target.value)}
          />
          <input
            className='form-control'
            type='text'
            placeholder='Event'
            required
            value={DNDTitle}
            onChange={(e) => setDNDTitle(e.target.value)}
          />
          <input
            className='form-control text-muted'
            type='text'
            placeholder='Affected characters if any'
            value={DNDPerson}
            onChange={(e) => setDNDPerson(e.target.value)}
          />

          <button
            onClick={addItem}
            className='btn btn-primary'
            type='button'
            disabled={!DNDTitle}
          >
            Loggfør
          </button>
        </div>
      </div>
    </div>
  );
}

export default DndItems;
